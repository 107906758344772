import React, { type Node } from 'react';

import styled from 'styled-components';

const Container = styled.div`
  .ant-form-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
      flex: unset;
    }

    /* Move error message to the left of the input */
    .ant-form-item-control {
      flex-direction: row-reverse;

      .ant-form-item-explain {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
      }
    }
  }
  .ant-form-item-label {
    font-weight: 600;
    padding: 0;
  }
`;

const DescriptionBox = styled.div`
  background-color: #fafafa;
  padding: 0.5rem;
  margin-top: 0.25rem;
`;

const DefaultDescriptionBox = styled.div`
  background-color: #f5f5f5;
  padding: 0.5rem;
  margin-top: 0.25rem;
`;

type propsT = {
  description: Node,
  defaultBehaviorText?: Node,
  children?: Node,
};
export const AutomationRuleOption = ({ description, defaultBehaviorText, children }: propsT): Node => (
  <Container>
    {children}
    <DescriptionBox>{description}</DescriptionBox>
    {defaultBehaviorText && <DefaultDescriptionBox>{defaultBehaviorText}</DefaultDescriptionBox>}
  </Container>
);
