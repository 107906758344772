export const i18n = (variables) => ({
  error: {
    401: '未经授权的请求：请登录后重试',
    404: `${variables.resource ? variables.resource : '资源'} 未找到`,
    teamAdd: '用户已存在',
    unavailable: '无法联系服务器',
    unableToGetStats: '无法获取统计数据',
    apiError: '发生错误，请联系您的管理员',
  },
  action: {
    copied: '已复制到剪贴板。',
    drag: {
      multipleRedirects: `您已经进行 ${variables.nbRedirects} 次重定向文件`,
    },
  },
  endOfTrial: {
    trial: `恭喜！您已完成了您的 ${variables.business ? '产品' : ''} 试用.`,
    revoked: '您的优惠券已失效。',
    content: '希望我们已经帮助您在广告活动中取得期望的效果。要继续使用 PixelMe，请选择最适合您需求的订阅计划。',
    button: '查看付费订阅计划',
  },
  head: {
    shortener: '短链接生成器',
    analytics: '分析器',
    billing: '账单',
    settings: '设置',
    faq: '常见问题解答',
    onboarding: '注册',
    accounts: '账户',
    asinAudit: 'ASIN审计报告',
  },
  subCampaigns: {
    name: '子文件夹名称',
    title: '子文件夹',
    add: {
      button: '创建',
      title: '增加子文件夹',
    },
    delete: {
      button: '删除',
      title: '删除子文件夹',
      help: '您的所有重定向文件将被移回到未分类文件夹里。',
    },
    edit: {
      button: '保存',
      title: '编辑子文件夹',
    },
    addRedirects: {
      success: `${variables.nbRedirects} 重定向文件成功加入子文件夹 ${variables.subCampaign}, 文件夹  ${variables.campaign}`,
    },
  },
  campaigns: {
    allCampaigns: '我的子文件夹',
    allLinks: '所有链接',
    name: '文件夹名称',
    title: '文件夹',
    select: {
      title: '文件夹',
      placeholder: '选择文件夹',
    },
    add: {
      button: '创建',
      title: '文件夹',
    },
    delete: {
      button: '删除',
      title: '删除文件夹',
      help: '您的所有重定向文件将被移回到未分类文件夹里。',
    },
    edit: {
      button: '保存',
      title: '保存文件夹',
    },
    addRedirects: {
      success: `${variables.nbRedirects} 重定向文件成功加入文件夹 ${variables.campaign}`,
    },
    subCampaigns: {
      select: {
        title: '子文件夹',
        placeholder: '选择子文件夹',
      },
    },
    keywords: {
      label: '关键词',
      add: '增加关键词',
      bulkAdd: '批量增加关键词',
      bulkAddPlaceholder: '每行输入一个关键词',
      invalid: '无效关键词',
    },
    matchType: {
      label: '匹配类型',
      learnMore: '更多e',
      broad: '宽泛匹配',
      broadDescription:
        '广告可能会显示在与您的关键词相关的搜索结果中，这可能包括不含关键词的搜索结果。这有助于吸引更多访问者到您的网站，节省建立关键词列表的时间，并将您的花费集中在有效的关键词上。宽泛匹配是所有关键词默认的匹配类型，因此您无需指定其他匹配类型（精确匹配、词组匹配或负匹配）。',
      exact: '精确匹配',
      exactDescription:
        '广告可能会显示在与关键词具有相同含义或相同意图的搜索结果中。在三种关键词匹配选择中，精确匹配为您提供了对谁可以看到您的广告最多的控制，但其覆盖的搜索结果少于词组匹配和宽泛匹配。',
      phrase: '词组匹配',
      phraseDescription:
        '广告可能会显示在包含您关键词含义的搜索结果中。关键词的含义可能是暗示性的，用户的搜索可能是该含义的更具体形式。使用词组匹配，您可以获得比精确匹配更多的搜索结果，但比宽泛匹配更少，仅在包含您的产品或服务的搜索结果中显示您的广告。',
    },
    adsProviderSuccess: `您的活动已经上传并将被审核 ${variables.adsProviderType}.`,
  },
  campaignAutomations: {
    title: '广告系列自动化规则',
    shortTitle: '自动化功能规则',
    description:
      '通过针对关键词和出价的自动化管理功能来优化您的广告系列。根据战略目标调整您的花费和绩效设置，确保您的广告投入能够带来最佳绩效。',
    descriptionHighlight:
      '在下一部分，您可以通过将状态更改为“启用”或“禁用”，选择将您的自定义规则是否覆盖 PixelMe 的默认自动化规则。',
    tooltip: '自动化设置',
    options: {
      default: 'PixelMe 默认设置',
      enabled: '启用',
      disabled: '禁用',
    },
    keywordPauser: {
      title: '关键词暂停器',
      description: '启用关键词暂停器，能够自动暂停ACOS过高的关键词（一旦启用，您可以在下方设置最高ACOS）。',
      maxAllowedAcosPerKw: {
        title: '每个关键词的ACOS上限',
        description:
          '为可接受的关键词表现设置ACOS上限。如果一个关键词超过了ACOS上限，它将被自动暂停。例如，如果ACOS上限为150%，而某个关键词的ACOS为200%，则该关键词将被关键词暂停器暂停。',
      },
      reviewPausedKeywords: {
        title: '获取延迟归因数据，审查已暂停的关键词',
        description:
          '归因数据可能会延迟最多14天。Pixelme 将自动使用更新后的归因数据审查之前暂停的关键词。如果审查后，发现一个关键词的ACOS低于设置的ACOS上限，将重新启用该关键词。',
      },
      maxAdSpendPerKw: {
        title: '每个关键词的广告花费上限',
        description:
          '如果一个关键词的广告花费已达到设置金额，但没有产生销售，则该关键词将被暂停。我们推荐您将这个数字设置为产品价格的2倍。这样可以确保我们有足够的数据支持暂停此关键词的决定。例如，如果您的产品价格是30美元，您应该将这个数字设定为60美元。',
      },
      guaranteedActiveKwCount: {
        title: '保证一定数量的活跃关键词',
        shortTitle: '保证一定数量的活跃关键词',
        description:
          '为确保广告系列继续投放, 请设置您希望在此广告系列中保持活跃状态的关键词数量。例如，如果您将此数字设置为3，我们将始终保持此广告系列中至少有3个关键词处于活跃状态，并且不会暂停更多的关键词。一些客户喜欢暂停所有表现不佳的关键词，因此他们将此设置为零，而其他客户通常会选择一个从1到5的数字。',
      },
    },
    automatedBidding: {
      title: '自动出价',
      description:
        '通过自动出价功能, 我们会自动调整谷歌关键词的每次点击出价 (CPC Bid), 帮助实现您广告系列的最佳效果。启用此自动化功能后，我们的系统需要最多2周的时间来正确学习和优化出价。',
    },
    negateKeywords: {
      title: '否定关键字',
      description: '这种自动化是针对单独广告的一次性自动化，会在使用短语匹配策略的广告中插入否定关键字列表',
    },
    keywordHarvesting: {
      title: '关键词收取',
      description:
        '这一自动化功能将识别表现优秀的搜索词，并将其作为精确匹配的关键词添加到广告中。 这帮助改善该广告的关键词目标。表现优秀的搜索词意味着它们将会30%地提升该广告的表现（与过去45天的表现相比），并且实现3个或更多的转换。',
      strategy: {
        title: '关键词收集策略',
        customAcos: {
          name: '自定义ACOS',
          description: '允许您设置关键词收集的ACOS目标。如果搜索词的ACOS等于或低于目标，则将其添加到广告系列中。',
        },
        default: {
          name: '默认规则',
          description: '使用PixelMe默认规则，即这比过去45天的广告系列表现高出30%。',
        },
      },
      acosTarget: {
        title: '目标ACOS',
        description: '某个搜索词需要达到预设的ACOS目标，以便将其收集并添加到广告活动中。',
      },
    },
  },
  utm: {
    source: {
      select: {
        title: 'UTM来源',
        placeholder: '选择一个来源',
      },
    },
    medium: {
      select: {
        title: 'UTM媒介',
        placeholder: '选择一个媒介',
      },
    },
    campaign: {
      select: {
        title: 'UTM广告',
        placeholder: '选择一个广告',
      },
    },
    term: {
      input: {
        title: 'UTM词组',
        placeholder: '付费关键词',
      },
    },
    content: {
      input: {
        title: 'UTM内容',
        placeholder: '差异化广告',
      },
    },
  },
  redirectCreator: {
    title: '您追踪的重定向',
    campaigns: {
      title: '已加入',
    },
  },
  composer: {
    button: '创建一个链接',
    create: {
      head: '创建一个链接',
      cardAlert: '需要添加有效的信用卡创建链接',
      cardAlertTooltip:
        '我们只需要信用卡来验证您的账户，以便创建链接。我们不会向您的信用卡扣款。只有当您升级到付费订阅时才会收费。',
    },
    ads: {
      title: 'Amazon Attribution',
      help: '',
      monthlyCost: '月广告支出',
      enabled: '月广告支出',
    },
    edit: {
      head: '编辑链接',
    },
    redirect: {
      edit: {
        title: '保存更新',
      },
      save: {
        title: '创建链接',
      },
      title: '重定向像素',
      help: '添加重定向像素并缩短您的重定向',
    },
    utms: {
      title: 'UTM参数',
      help: '增加UTM参数到您的目的地链接到您的目标URL',
    },
    domains: {
      title: '品牌域名',
      help: '',
    },
    description: {
      title: '描述',
      help: '',
    },
    campaigns: {
      title: '文件夹和标签',
      help: '',
    },
    rotatorRedirect: {
      title: 'URL轮换器',
      help: '',
    },
    dynamicRedirect: {
      title: '动态链接',
      help: '',
      addRule: '增加规则',
      url: '目标Url',
      rule: '动态规则',
    },
    errors: {
      redirect: '您正在尝试创建的链接也是一个重定向，请使用最终目标作为URL。',
      invalidURL: '您输入的URL无效，请输入一个有效的URL。',
      blockedDomain: '您试图使用的域是不允许的。',
      unreachable: '您正在尝试使用的 URL 是不可访问的。',
    },
  },
  redirect: {
    url: {
      input: {
        title: '目标URL',
        placeholder: '粘贴您的URL',
      },
    },
    slug: {
      input: {
        title: '短链接',
        placeholder: '',
      },
    },
    title: {
      input: {
        title: '标题',
        placeholder: '',
      },
    },
    filter: {
      dates: {
        all: '无限期',
        day7: '最近7天',
        day14: '最近14天',
        day30: '最近30天',
        day90: '最近90天',
      },
    },
    export: {
      title: 'CSV文件导出',
      help: `导出 ${variables.nbRedirects} 个重定向文件  ${
        variables.campaignName ? `来自广告系列 ${variables.campaignName}` : ''
      } ${variables.subCampaignName ? `>${variables.subCampaignName}` : ''}`,
      list: {
        help: '选择要导出的列',
      },
      button: {
        title: '导出',
      },
      success: {
        help: '您将收到一个重定向文件的CSV文件。在网络高峰时段, 这可能需要几分钟。',
        feedback: {
          title: '用户反馈:',
          help: '您能给我们关于CSV导出的反馈吗? 为什么您需要导出？您想要导出的是什么？ ',
          placeholder: '这里是您的反馈...',
        },
      },
      feedback: {
        button: {
          title: '完成',
        },
      },
    },
  },
  members: {
    delete: `确定要将
${variables.email} 从 "${variables.orgName}" 中移除吗?`,
    list: {
      name: '姓名',
      email: '邮箱地址',
      signedUp: '成功注册',
      delete: '删除',
    },
  },
  links: {
    link: {
      favIcon: '',
      title: '标题',
      shorten: '短链接',
      pixel: '像素',
      clicks: '点击',
      tags: '标签',
      source: 'UTM来源',
      medium: 'UTM媒介',
      campaign: 'UTM广告系列',
      term: 'UTM词组',
      content: 'UTM内容',
      url: '目标URL',
      domain: '域',
      slug: 'Slug',
      cookieConsent: 'GDPR同意书',
      createdAt: '创建日期',
    },
  },
  redirects: {
    empty: variables.campaignOrSubName
      ? `在${variables.campaignOrSubName}文件夹里您没有任何重定向文件,<br/> 创建一个新的重定向文件，或者从其它文件夹用鼠标拖拉一个重定向文件`
      : '您没有任何重定向文件在看板上,<br/> 创建一个新的重定向文件，或者从其它文件夹用鼠标拖拉一个重定向文件',
    unclassified: '未分类链接',
    allRedirects: '所有链接',
    redirect: {
      favIcon: '',
      title: '标题',
      shorten: '短链接',
      pixel: '像素',
      clicks: '点击',
      adsCost: '广告成本',
      tags: '标签',
      source: 'UTM来源',
      medium: 'UTM媒介',
      campaign: 'UTM广告系列',
      term: 'UTM词组',
      content: 'UTM内容',
      url: '目标URL',
      domain: '域',
      slug: 'Slug',
      cookieConsent: 'GDPR同意书',
      createdAt: '创建日期',
    },
  },
  settings: {
    profile: {
      language: {
        title: 'PixelMe 语言控制',
        languages: {
          en: '英语',
          zh: '中文',
        },
      },
      reset: {
        title: '重置我的缓存',
        button: {
          title: '重置我的缓存并退出',
        },
      },
    },
    trackingCode: {
      title: '转化追踪代码',
      htmlBlock: {
        title: 'HTML追踪代码',
        subtitle: '复制以下追踪代码, 并将其粘贴到您希望追踪事件的任何HTML页面的闭合head标签之前。',
        isConnectedWidget: {
          connected: '连接',
          disconnected: '未连接',
        },
        buttonCTA: '复制',
        buttonOKNotification: '代码已复制到剪贴板。',
        help: '需要帮助 ?',
        FAQ: '请阅读FAQ',
      },
      eventBlock: {
        title: '追踪事件',
        titleLegend: '(推荐)',
        subtitle: '从我们的预设事件集合中选择，或者通过编辑代码样本创建您自己的事件。',
        subtitleCustomEvents: '追踪访问者在您的网站上执行的关键操作，以查看哪些事件和客户路径导致更高的转化率。',
        subtitleCustomEventsVariables: '不要忘记替换XXXX.',
        buttonCTA: '复制',
        buttonOKNotification: '代码已复制到剪贴板。',
        instructions: {
          pickEvent: '1. 选择一个事件',
          copyEvent: '2. 将事件脚本复制粘贴到您的应用程序中',
        },
        help: '需要帮助 ?',
        FAQ: '请阅读FAQ',
      },
      modal: {
        title: '编辑您的事件',
        displayName: '显示名称',
        displayNamePlaceholder: '例如，注册',
        archived: '显示此事件',
        save: '保存',
      },
      myEventBlock: {
        title: '我的已追踪事件',
        titleLegend: '(推荐)',
        subtitle: '点击一个事件来编辑名称或将其从您的看板中隐藏。',
        eventName: '事件名称',
      },
    },
  },
  billing: {
    plans: {
      periodSwitcher: {
        monthly: '月版',
        yearly: '年版',
        yearlyBonus: ' 打折最多至七折',
      },
      type: {
        marketer: '面向市场营销人员',
        amazon: '面向亚马逊卖家',
      },
      planButtons: {
        current: '目前订阅计划',
        next: '下一级订阅计划',
        reEnable: '激活订阅计划',
        upgrade: '升级',
        downgrade: '降级',
        notActive: '非活跃用户',
        previewPrice: '查看价格',
        trial: '开始免费试用',
        businessTrial: '开始企业版免费试用',
        switchMonthly: '切换到月版',
        switchYearly: '切换到年版',
        manageSubscription: '管理订阅计划',
      },
      planDetails: {
        saleFlag: '价格最优',
        perMonth: ' /每月',
        billedYearly: ' (年付款)',
        upTo: '最多',
        startingAt: '从',
        visitors: '追踪的点击',
        trackingScript: '追踪脚本',
        eventTracking: '追踪事件',
        attribution: '归因',
        billed: '已出账单',
      },
      needMoreVisitors: '企业用户? ',
      needToChangeMyIBAN: '你好PixelMe团队, 能帮我更改我的IBAN吗?',
      contactUs: '联系我们!',
      cancel: '取消我的订阅',
    },
  },
  signUp: {
    formSection: {
      subText: {
        'sign up': '报名',
        'ltdf': '为营销人员提供的缩短URL工具',
        'appsumo': '为营销人员提供的缩短URL工具',
        'stackcommerce': '为营销人员提供的缩短URL工具',
      },
      cta: {
        'sign-up': '立即创建您的免费PixelMe账户',
        'appsumo': '立即创建您的免费PixelMe账户',
        'ltdf': '立即创建您的免费PixelMe账户',
        'stackcommerce': '立即创建您的免费PixelMe账户',
      },
      signUpBtn: '立即注册!',
    },
    specialOffer: {
      title: '欢迎加入PixelMe的特殊优惠活动!',
      subTitle: '包含在您的Pro订阅计划中',
    },
  },
  accounts: {
    reporting: '报表',
  },
  asinAudit: {
    title: 'ASIN审计',
    editTitle: '编辑审计',
    newAudit: {
      title: '新的审计',
      submit: '开始新的审计',
      submitAnother: '生成新的审计',
      success: '成功创建审计',
      clearAll: '清除所有',
    },
    auditResults: {
      title: '审计结果',
      back: '回到审计结果页面',
    },
    auditHistory: {
      title: '审计历史',
      back: '回到审计历史页面',
    },
    sellerId: {
      search: '根据Seller ID进行审计',
      searchDescription: '输入您想要我们检查的卖家ID。',
      placeholder: '输入Seller ID',
      error: 'Seller ID必须包含12-14个字母和数字字符',
      details: 'Seller ID的审计详情',
      requiredError: '请输入正确的Seller ID',
    },
    asin: {
      search: '根据ASIN进行审计',
      searchDescription: `输入最多${variables.max}个ASIN`,
      placeholder: 'Enter ASINs',
      label: 'ASIN',
      error: 'ASIN必须包含10个字母数字字符。',
      errorDescription: '格式错误的ASIN将不会显示在结果中。',
      detailsMultiple: 'ASIN的审计详情',
      detailsSingle: 'ASIN的审计详情',
      requiredError: '请至少输入一个有效的ASIN',
      competitorsDescription: '您可以添加竞争 ASIN',
      competitorsPlaceholder: '输入竞争 ASIN (可选）',
      competitorsHelp: '逗号分隔，例如 B073XSHTWR、B09Q7JY4Q3',
    },
    contactInformation: {
      title: '联系人信息',
      name: '姓名',
      firstName: '名',
      lastName: '姓',
      email: '电子邮件地址',
      emailExample: 'example@example.com',
      emailError: '请输入一个有效的电子邮件地址',
      phone: '电话号码',
      countryCode: '国家代码',
      areaCode: '地区代码',
      notes: '备注',
      notePlaceholder: '您认为与您的个人资料相关的任何内容',
      submit: '保存联系信息',
      success: '联系信息已成功保存',
    },
    product: {
      title: '产品',
      asin: 'ASIN',
      brand: '品牌',
      price: '客单价',
      reviewCount: '评论数',
      reviewScore: '评分',
      keywords: '关键词',
      profitScore: '利润得分',
      rankingScore: '排名得分',
      strategy: '策略',
      defaultTitle: '[未命名产品]',
    },
    keyMetrics: {
      profitScore: '利润得分',
      rankingScore: '排名得分',
      keywordProfitScore: '关键词利润得分',
      amazonSearchVolume: '亚马逊搜索量',
      googleSearchVolume: '谷歌搜索量',
      keywordRankingScore: '关键词排名得分',
      reviewScore: '评分',
      reviewCount: '评论数',
      price: '客单价',
    },
    keywords: {
      title: '关键词',
      amazonSearchVolume: '亚马逊搜索量',
      amazonCurrentRank: '亚马逊当前排名',
      amazonCompetitorRank: '亚马逊竞品排名',
      googleSearchVolume: '谷歌搜索量',
      googleEstCostPerClick: '谷歌估计单次点击成本',
      keywordProfitScore: '关键词利润得分',
      keywordRankScore: '关键词排名得分',
    },
    preview: {
      description:
        '根据我们的经验, 我们发现了一些有潜力的ASIN, 我们相信它们可以通过亚马逊站外广告活动提升您的自然排名，或者为您开发一个新的有利可图的收入渠道。',
      strategyTooltip:
        '利润策略 = 我们预测这个产品可以通过谷歌广告实现盈利广告。\n\n排名策略 = 我们预测可以使用谷歌广告来提高亚马逊的自然排名。',
      asvTooltip: '这是在亚马逊上每月针对为这项策略选择的关键词进行的搜索量。',
      gsvTooltip: '这是针对为此策略选择的关键词在谷歌上每月的搜索量。',
      oppKeywordsTooltip: '我们为这项策略确定的关键词数量。',
      oppTooltip: '这个机会的置信水平。非常高表示很好，中等表示这是一个平均的机会。',
      impactScoreTooltip: '我们认为这种亚马逊站外策略将对此ASIN产生的影响',
      bookCall: '预订一个和专业顾问的电话会议，审查您的关键词和推荐策略',
      bookCallEmpty: '我们无法找到此卖家 ID 的任何 ASIN 机会。请预约与顾问通话以启动新的审核',
      noData: '我们无法找到此商店的任何 ASIN 机会',
    },
    pageNotAvailable: '此页面不存在',
    bookCall: '预订一个电话会议',
    topKeywords: '热门关键词',
    topCompetitors: '热门竞品',
    loading: '我们正在运行您的审计报告。\n结果可能需要最多5分钟。\n您可以离开此页面, 稍后返回',
    auditCreated: '审计报告已创建',
    auditUpdated: '审计报告已更新',
    asinsOrSellerIds: 'ASINs/Seller ID',
    status: '状态',
    action: '行动',
    impactScore: '影响得分',
    opportunityKeywords: '机会关键词',
    missingId: '结果将在生成新的审计报告后出现，\n或者从历史审计标签页中打开审计报告。',
    failed: '审计报告生成失败。\n请重试或联系支持。',
    noResults: '结果未找到',
    share: {
      title: '分享结果',
      inputLabel: '邮件发送',
      inputPlaceholder: '输入一个或者多个邮箱地址',
      submit: '分享',
      copyLink: '复制链接',
      clipboard: '链接已复制到剪贴板',
      sentTo: '发送邮件至',
      sentSuccess: `邮件发送到${variables.numOfEmails}个收件人`,
      pageTitle: 'PixelMe ASIN审计报告',
    },
    strategies: {
      profit: '利润',
      rank: '排名',
    },
    auditImprovement: {
      editKeywords: '编辑热门关键词',
      searchKeywords: '搜索关键词',
      addKeywords: '添加我自己的关键词',
      keywordsUpdated: '关键词已更新',
      newKeywordsDescription: '在每一行中添加您自己的关键词',
      addToTopKeywords: '添加到热门关键词',
    },
  },
  productTags: {
    title: '标签',
    description: '请输入最多五个产品标签。一个好的标签选择可以是产品品牌或类目。',
    placeholder: '用标签筛选',
  },
  facebook: {
    campaignObjective: {
      appPromotion: '应用推广',
      awareness: '认知度',
      engagement: '参与度',
      leads: '潜在客户',
      sales: '销售额',
      traffic: '流量',
      placeholder: '选择活动目标',
      title: '活动目标',
    },
    performanceGoals: {
      offsiteConversions: '最大化转化次数',
      value: '最大化转化价值',
      linkClicks: '最大化链接点击次数',
      placeholder: '选择绩效目标',
      title: '绩效目标',
    },
  },
  facebookAds: {
    title: 'Facebook 广告',
    reconnect: '重新连接 Facebook 广告',
    connect: '连接 Facebook 广告',
    accounts: {
      title: 'Facebook 帐户',
      noName: '无描述性名称',
      description: '在下面的列表中选择一个 Facebook 帐户。',
      connect: '连接您的帐户',
    },
  },
  googleAds: {
    title: 'Google Ads',
    reconnect: '重新连接 Google Ads',
    connect: '连接 Google Ads',
    accounts: {
      title: 'Google Ads 帐户',
      description: '在下面的列表中选择一个 Google Ads 帐户。',
      connect: '连接您的帐户',
    },
    editCampaign: '在 Google Ads 上编辑',
  },
  adsProvider: {
    googleAdsPrompt: '还没有 Google Ads 帐户？',
    loginSuccess: {
      title: '您已全部设置完毕！',
      description: `在 ${variables.provider} 上创建广告系列，并使用 Amazon Attribution 跟踪所有广告系列的结果。`,
      createCampaign: '创建您的第一个广告系列',
    },
  },
  amazonOnboarding: {
    title: '从 PixelMe 发布广告',
    description: '在 Google、Facebook 或 Tiktok 上创建广告活动，并使用亚马逊归因跟踪所有广告活动的结果。',
    createCampaign: '创建亚马逊归因广告活动',
    additionalInfo: '您还可以创建自定义归因链接。',
    additionalInstructions: '单击上方即可开始使用',
  },
  adsProviderReLoginModal: {
    migration: {
      title: '重新连接 Google Ads 帐户',
      description:
        '我们刚刚完成了对 Google Ads API 的多项升级。这使我们的 Google Ads 服务更加可靠，并提高了我们的互动速度。请在下面重新连接您的 Google Ads 帐户以升级您的连接',
    },
    login: {
      title: '广告帐户已断开连接',
      errorTitle: '您的广告帐户已断开连接。请重新连接。',
      errorDescription: '如果问题仍然存在，请联系我们。',
    },
  },
  variations: {
    keywordRankTrendTitle: '关键字排名趋势',
    keywordRankTrendTooltip: `选定日期范围内前 ${variables.avgLength} 个排名与后 ${variables.avgLength} 个排名的移动平均值。`,
  },
  global: {
    dates: {
      all: '终身',
      day7: '最近7天',
      day14: '最近14天',
      day30: '最近30天',
      day60: '最近60天',
      day90: '最近90天',
      day365: '最近365天',
      week1: '上周',
      month1: '上个月',
      month3: '最近3个月',
      month12: '最近12个月',
      ALL: '终身',
      LAST_7D: '最近7天',
      LAST_30D: '最近30天',
      LAST_90D: '最近90天',
      allPixelMe: 'PixelMe终身使用',
    },
    back: '返回',
    beta: 'Beta版',
    add: '增加',
    ok: '确定',
  },
  locales: {
    profile: {
      accountDetails: '帐户详细资料',
      profileDetails: '个人资料详细信息',
      cancel: '取消',
      updateAccount: '更新帐户',
      edit: '编辑',
    },
    teamMembers: {
      owner: '所有者',
      addMember: '添加成员',
      myTeamMembers: '我的团队成员',
    },
    dash: {
      info: '选择下面的产品来创建广告系列',
      newProduct: '新产品',
      searchForAnything: '搜索任何内容',
      campaignStatus: '广告系列状态',
      allMarketingChannels: '所有营销渠道',
      allCampaigns: '所有广告系列',
      noProducts: '未找到任何产品',
      infoAttribution: '如果您没有归因数据，这可能是由于受众过于针对性且受众规模较小。',
      noCampaignsError: '此产品没有可用的广告活动',
      deleteConfirm: '您确定要删除吗？',
      campaignRunning: '广告活动正在进行中',
      adDataReceived: '已收到广告数据',
      amazonDataReceived: '亚马逊数据已接收',
      warning: '警告：数据已过期。',
      contactSupport: '如需更多信息，请联系支持人员。',
    },
    headers: {
      teamMembers: '团队成员',
      profile: '个人资料',
      lifetimeCoupon: '终身优惠券',
      usage: '使用情况',
      invoices: '发票',
      subscription: '订阅',
      cc: '信用卡详细信息',
      sepa: 'SEPA 详细信息',
      links: '链接',
      linkAnalytics: '链接分析',
      bulkImport: '批量导入',
      settings: '设置',
      lButton: '链接按钮',
      appearance: '外观',
      analytics: '分析',
      aa: '亚马逊归因',
      apiAccounts: 'API 帐户',
      products: '产品',
      oneLink: 'OneLink',
      linkManager: '链接管理器',
      aaCampaigns: '亚马逊归因 广告系列',
      dashboard: '数据看板',
    },
    columns: {
      createdAt: '创建日期',
      amazonRanking: '亚马逊排名',
      firstPositionEstBid: '第一名预估出价',
      firstPageEstBid: '第一页估计出价',
      avgBrandReferralBonus: '平均品牌推荐奖励',
      acos: 'ACOS',
      roas: 'ROAS',
      netMargin: '净利润',
      revenue: '营收',
      purchases: '购买',
      addToCart: '添加到购物车',
      attributedCpc: '归因于 CPC',
      attributedAdCost: '归因广告成本',
      attributedClicks: '归因点击',
      impressions: '印象数',
      clicks: '点击量',
      attributedImpressions: '归因印象',
      status: '地位',
      matchType: '匹配类型',
      keyword: '关键词',
      adGroupName: '广告组名称',
      totalAdCost: '总广告费用',
      providerStatus: '广告提供商状态',
      keywords: '关键词',
      location: '位置',
      cpc: 'CPC',
      adSpend: '广告支出',
      campaignName: '营销活动名称',
      productName: '品名',
      productASIN: '产品 ASIN',
      amazonSearchVolume: '亚马逊搜索量',
      amazonCurrentRank: '亚马逊当前排名',
      googleSearchVolume: 'Google 搜索排名',
      googleEstCostPerClick: 'Google 预估每次点击费用',
      liveImpressions: '实时印象',
      liveClicks: '实时点击',
      liveAdCost: '实时广告费用',
      liveCPC: '即时的 CPC',
      rawData: '原始资料',
      conversionRate: '转化率',
      addToCartRate: '加入购物车率',
      clickThroughRate: '点击率',
      newToBrandPurchases: '首次购买品牌商品',
      newToBrandRevenue: '新的品牌收入',
      dataProviders: '数据提供商',
      campaigns: '广告系列',
      viewLastUpdate: '查看最后更新',
      accountName: '帐户名称',
    },
    billing: {
      amazSeller: '对于亚马逊卖家',
      attributionLinksOnly: '仅限归因链接',
      forMarketers: '对于营销人员',
      talkToUs: '与我们交谈',
      ccm: '营销活动创建和管理',
      googleAdCamp: 'Google 广告活动',
      facebookAdCamp: 'Facebook 广告活动',
      tiktokAdCamp: 'TikTok 广告活动',
      retargetingPixels: '重定目标像素',
      amazonProducts: '亚马逊产品',
      microLandingPages: '微型登陆页面',
      organicRankTracking: '自然排名追踪',
      keywordLevelConversions: '关键词层面的转化',
      dedicatedAccountManager: '专属客户经理',
      dedicatedPhoneSupport: '专用电话支持',
      customCreativeServices: '定制创意服务',
      multipleAmazonAccounts: '多个亚马逊账户',
      trackedClicksPerMonth: '每月追踪的点击次数',
      customDomain: '自定义域',
      deepLink: '深层链接',
      dedicatedCustomerSupport: '专门的客户支持',
      seats: '座位数',
      customPrice: '定制价格',
      noAdsError: '此计划中无广告。若要创建 Google、Facebook、Tiktok 广告，请升级至专业计划',
      contact: '接触我们',
      unlimited: '无限',
      overview: '概述',
      plan: '计划',
      created: '已创建',
      currentPeriod: '本期',
      nextInvoice: '下一张发票',
      monthlyClicksUsage: '每月点击量使用量',
      included: '包括在内的',
      used: '用过的',
      usageReset: '重置使用情况',
      limits: '限制',
      brandedLinks: '品牌链接',
      customDomains: '自定义域',
      teamMembers: '组员',
      workspaces: '工作区',
      notIncluded: '不包含在您的计划中',
    },
    oneLink: {
      createMyPage: '创建我的页面',
      chooseUrl: '选择你的微型登陆页面的 URL',
      info: 'PixelMe OneLink 是几分钟内构建微型网站的最佳方式，无需编码',
      welcome: '欢迎来到 PixelMe OneLink!',
    },
    accounts: {
      accountSwitcher: '帐户切换器',
      subscriptionStatus: '订阅状态',
      googleAdsIds: 'Google 广告 IDs',
      activeAndTotalCampaigns: '活跃/总广告活动',
      startDate: '开始日期',
      dailyBudget: '每日预算',
      cost: '成本',
      averageCpc: '平均的 CPC',
      addToCarts: '添加到购物车',
      conversions: '转换',
      conversionRate: '转化率',
      unitsSold: '已售单位',
      acosRange: 'ACOS范围',
      searchForAccountsByName: '按名称搜索帐户',
      impressions: '印象数',
      clicks: '点击量',
      revenue: '营收',
      roas: 'ROAS',
      acos: 'ACOS',
      brandReferralBonus: '品牌推荐奖金',
      addToCartRate: '加入购物车率',
      clickThroughRate: '点击率',
    },
    campaigns: {
      newLink: '创建新链接',
      newCampaign: '新活动',
      importCampaigns: '导入广告系列',
      campaignError: '当前计划不支持广告活动，请升级至专业计划以启用广告活动',
    },
    variations: {
      info: '这里显示的数据受到亚马逊购物者隐私政策的限制, 仅显示点击次数超过10次的关键词的购买数据。',
      infoTwo: '点击次数少于10次的关键字将显示为零, 直到达到点击次数的阈值。',
    },
  },
});
