import React, { useState, type Node } from 'react';

import { Form, InputNumber, Switch } from 'antd';
import useIntl from 'react-intl/lib/src/components/useIntl';
import styled from 'styled-components';

import { CurrencyInput } from 'components/common/antd/inputs/CurrencyInput';
import { AUTOMATIONS_KEYWORD_HARVESTING, useFeatureFlag } from 'lib/configcat';
import { strings } from 'locales/strings';

import { AutomationRule, OPTIONS } from './AutomationRule';
import { AutomationRuleOption } from './AutomationRuleOption';
import { KeywordHarvestingRule } from './KeywordHarvestingRule';
import initial from 'lodash/fp/initial';

const Container = styled.div`
  display: grid;
  row-gap: 1.5rem;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-number,
  .ant-input-number-affix-wrapper {
    &,
    div {
      height: 100%;
    }
  }
`;

const StyledInputNumber = styled(InputNumber).attrs({ controls: false })`
  width: 110px;

  input {
    outline: none;
    text-align: right;

    &.ant-input-number-input {
      padding-right: 11px;
    }
  }
`;

const Highlight = styled.p`
  font-weight: 600;
`;

const defaultInitialValues = {
  automationRules: {
    keywordPauser: {
      enabled: OPTIONS.DEFAULT.value,
      maxAllowedAcosPerKw: 0,
      reviewPausedKeywords: true,
      maxAdSpendPerKw: 0,
      guaranteedActiveKwCount: 0,
    },
    automatedBidding: {
      enabled: OPTIONS.DEFAULT.value,
    },
    negateKeywords: {
      enabled: OPTIONS.DEFAULT.value,
    },
    keywordHarvesting: {
      strategy: 'default',
      enabled: OPTIONS.DEFAULT.value,
      acosTarget: 0,
    },
  },
};

export const getAutomationsPayload = (values, isKwHarvestingFlagEnabled, isFlagLoading) => {
  const automationRules = {};

  for (const [key, value] of Object.entries(values.automationRules)) {
    automationRules[key] = value.enabled === OPTIONS.DEFAULT.value ? null : { ...value };
  }

  if (automationRules.keywordPauser) {
    const { maxAdSpendPerKw } = automationRules.keywordPauser;

    automationRules.keywordPauser.maxAdSpendPerKw = Math.round(maxAdSpendPerKw);
  }

  if (!isFlagLoading && !isKwHarvestingFlagEnabled) {
    if (automationRules.keywordHarvesting && automationRules.keywordHarvesting.enabled) {
      automationRules.keywordHarvesting = null;
    }
  }

  if (!isFlagLoading && isKwHarvestingFlagEnabled) {
    if (automationRules.keywordHarvesting) {
      const { strategy, acosTarget } = automationRules.keywordHarvesting;

      automationRules.keywordHarvesting = {
        enabled: automationRules.keywordHarvesting.enabled,
      };
      if (automationRules.keywordHarvesting.enabled === OPTIONS.ENABLED.value) {
        if (strategy === 'customAcos') {
          automationRules.keywordHarvesting.acosTarget = acosTarget;
        }
      }
    }
  }

  return { automationRules };
};

const getInitialFormValues = (initialValues) => {
  if (initialValues?.automationRules) {
    const automationRules = {};

    for (const [key, value] of Object.entries(defaultInitialValues.automationRules)) {
      if (key === 'keywordHarvesting') {
        if (initialValues.automationRules.keywordHarvesting) {
          if (initialValues.automationRules.keywordHarvesting.enabled === OPTIONS.ENABLED.value) {
            automationRules.keywordHarvesting = {
              ...initialValues.automationRules.keywordHarvesting,
              strategy: initialValues.automationRules.keywordHarvesting.acosTarget > 0 ? 'customAcos' : 'default',
            };
          } else {
            automationRules.keywordHarvesting = {
              ...initialValues.automationRules.keywordHarvesting,
              strategy: 'default',
            };
          }
        } else {
          automationRules[key] = value;
        }
      } else {
        automationRules[key] = initialValues.automationRules[key] ?? value;
      }
    }

    return { automationRules };
  }

  return defaultInitialValues;
};

type propsT = {
  currencyCode: string,
  initialValues?: {
    automationRules: automationRulesT,
  },
  onFinish: (automationRulesT) => void,
};

export const CampaignAutomationsForm = ({ currencyCode, initialValues, onFinish, form }: propsT): Node => {
  const { messages } = useIntl();
  const keywordPauserEnabled =
    Form.useWatch(['automationRules', 'keywordPauser', 'enabled'], form) === OPTIONS.ENABLED.value;
  const initialFormValues = getInitialFormValues(initialValues);
  const [isKeywordHarvestingFlagEnabled, isFlagLoading] = useFeatureFlag(AUTOMATIONS_KEYWORD_HARVESTING);
  const handleFinish = (values) =>
    onFinish(getAutomationsPayload(values, isKeywordHarvestingFlagEnabled, isFlagLoading));

  return (
    <Form form={form} initialValues={initialFormValues} onFinish={handleFinish} requiredMark={false} colon={false}>
      <Container>
        <div>
          <p>{messages.campaignAutomations.description}</p>
          <Highlight>{messages.campaignAutomations.descriptionHighlight}</Highlight>
        </div>

        {/* maxAllowedAcosPerKw and maxAdSpendPerKw are strictly > 0 only when keywordPauser is enabled */}
        <AutomationRule
          name={['automationRules', 'keywordPauser', 'enabled']}
          title={messages.campaignAutomations.keywordPauser.title}
          description={messages.campaignAutomations.keywordPauser.description}
          defaultBehaviorText={messages.campaignAutomations.keywordPauser.defaultBehaviorText}
        >
          <AutomationRuleOption
            description={messages.campaignAutomations.keywordPauser.maxAllowedAcosPerKw.description}
          >
            <Form.Item
              name={['automationRules', 'keywordPauser', 'maxAllowedAcosPerKw']}
              label={messages.campaignAutomations.keywordPauser.maxAllowedAcosPerKw.title}
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: keywordPauserEnabled ? 1 : 0,
                  message: 'Please enter a value > 0',
                },
              ]}
            >
              <StyledInputNumber prefix="%" min={0} precision={0} disabled={!keywordPauserEnabled} />
            </Form.Item>
          </AutomationRuleOption>
          <AutomationRuleOption
            description={messages.campaignAutomations.keywordPauser.reviewPausedKeywords.description}
          >
            <Form.Item
              name={['automationRules', 'keywordPauser', 'reviewPausedKeywords']}
              label={messages.campaignAutomations.keywordPauser.reviewPausedKeywords.title}
            >
              <Switch disabled checked />
            </Form.Item>
          </AutomationRuleOption>
          <AutomationRuleOption description={messages.campaignAutomations.keywordPauser.maxAdSpendPerKw.description}>
            <Form.Item
              name={['automationRules', 'keywordPauser', 'maxAdSpendPerKw']}
              label={messages.campaignAutomations.keywordPauser.maxAdSpendPerKw.title}
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: keywordPauserEnabled ? 1 : 0,
                  message: 'Please enter a value > 0',
                },
              ]}
            >
              <CurrencyInput
                currencyCode={currencyCode}
                unit={100}
                precision={2}
                size="middle"
                disabled={!keywordPauserEnabled}
              />
            </Form.Item>
          </AutomationRuleOption>
          <AutomationRuleOption
            description={messages.campaignAutomations.keywordPauser.guaranteedActiveKwCount.description}
          >
            <Form.Item
              name={['automationRules', 'keywordPauser', 'guaranteedActiveKwCount']}
              label={messages.campaignAutomations.keywordPauser.guaranteedActiveKwCount.title}
              rules={[{ required: true, message: 'Please enter a value ≥ 0' }]}
            >
              <StyledInputNumber min={0} precision={0} disabled={!keywordPauserEnabled} />
            </Form.Item>
          </AutomationRuleOption>
        </AutomationRule>

        <AutomationRule
          name={['automationRules', 'automatedBidding', 'enabled']}
          title={messages.campaignAutomations.automatedBidding.title}
          description={messages.campaignAutomations.automatedBidding.description}
          defaultBehaviorText={messages.campaignAutomations.automatedBidding.defaultBehaviorText}
        />
        <AutomationRule
          name={['automationRules', 'negateKeywords', 'enabled']}
          title={messages.campaignAutomations.negateKeywords.title}
          description={messages.campaignAutomations.negateKeywords.description}
          defaultBehaviorText={messages.campaignAutomations.negateKeywords.defaultBehaviorText}
        />
        <KeywordHarvestingRule />
      </Container>
    </Form>
  );
};

export { OPTIONS };
