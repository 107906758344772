import { AutomationRule, OPTIONS } from './AutomationRule';
import { AutomationRuleOption } from './AutomationRuleOption';

import { Form, InputNumber, Select, Switch } from 'antd';
import message from 'antd/es/message';
import { CurrencyInput } from 'components/common/antd/inputs/CurrencyInput';
import { AUTOMATIONS_KEYWORD_HARVESTING, useFeatureFlag } from 'lib/configcat';
import { useLocale } from 'locales/LocalizationWrapper';
import { strings } from 'locales/strings';
import React, { useState, type Node } from 'react';
import useIntl from 'react-intl/lib/src/components/useIntl';
import styled from 'styled-components';

const Container = styled.div`
  .ant-form-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
      flex: unset;
    }

    /* Move error message to the left of the input */
    .ant-form-item-control {
      flex-direction: row-reverse;

      .ant-form-item-explain {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
      }
    }
  }
  .ant-form-item-label {
    font-weight: 600;
    padding: 0;
  }
`;

const StyledInputNumber = styled(InputNumber).attrs({ controls: false })`
  width: 110px;

  input {
    outline: none;
    text-align: right;

    &.ant-input-number-input {
      padding-right: 11px;
    }
  }
`;

const DescriptionBox = styled.div`
  background-color: #fafafa;
  padding: 0.5rem;
  margin-top: 0.25rem;
`;

export const KeywordHarvestingRule = (): Node => {
  const { messages } = useIntl();
  const form = Form.useFormInstance();
  const keywordHarvestingEnabled =
    Form.useWatch(['automationRules', 'keywordHarvesting', 'enabled'], form) === OPTIONS.ENABLED.value;
  const keywordStrategy = Form.useWatch(['automationRules', 'keywordHarvesting', 'strategy'], form);
  const [isKeywordHarvestingFlagEnabled, isFlagLoading] = useFeatureFlag(AUTOMATIONS_KEYWORD_HARVESTING);

  const StrategyOptions = [
    { label: messages.campaignAutomations.keywordHarvesting.strategy.customAcos.name, value: 'customAcos' },
    { label: messages.campaignAutomations.keywordHarvesting.strategy.default.name, value: 'default' },
  ];
  return (
    <AutomationRule
      name={['automationRules', 'keywordHarvesting', 'enabled']}
      title={messages.campaignAutomations.keywordHarvesting.title}
      description={messages.campaignAutomations.keywordHarvesting.description}
      defaultBehaviorText={messages.campaignAutomations.keywordHarvesting.defaultBehaviorText}
    >
      <>
        {!isFlagLoading && isKeywordHarvestingFlagEnabled && (
          <>
            <Container>
              <Form.Item
                name={['automationRules', 'keywordHarvesting', 'strategy']}
                label={messages.campaignAutomations.keywordHarvesting.strategy.title}
              >
                <Select options={StrategyOptions} style={{ width: 155 }} disabled={!keywordHarvestingEnabled} />
              </Form.Item>
              <DescriptionBox>
                <p>
                  <b>{messages.campaignAutomations.keywordHarvesting.strategy.customAcos.name}</b>{' '}
                  {messages.campaignAutomations.keywordHarvesting.strategy.customAcos.description}
                </p>
                <p>
                  <b>{messages.campaignAutomations.keywordHarvesting.strategy.default.name}</b>{' '}
                  {messages.campaignAutomations.keywordHarvesting.strategy.default.description}
                </p>
              </DescriptionBox>
            </Container>
            {keywordStrategy === 'customAcos' && (
              <AutomationRuleOption description={messages.campaignAutomations.keywordHarvesting.acosTarget.description}>
                <Form.Item
                  name={['automationRules', 'keywordHarvesting', 'acosTarget']}
                  label={messages.campaignAutomations.keywordHarvesting.acosTarget.title}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      min: keywordHarvestingEnabled && keywordStrategy === 'customAcos' ? 1 : 0,
                      message: 'Please enter a value > 0',
                    },
                  ]}
                >
                  <StyledInputNumber
                    prefix="%"
                    min={0}
                    precision={0}
                    disabled={!keywordHarvestingEnabled || keywordStrategy === 'default'}
                  />
                </Form.Item>
              </AutomationRuleOption>
            )}
          </>
        )}
      </>
    </AutomationRule>
  );
};

export { OPTIONS };
